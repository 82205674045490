export const getRiskCategoryImage = (riskCategory) => {
    return riskCategory && `/images/risk/${riskCategory}Risk.svg`;
};

export const getRiskCategoryName = (riskCategory) => {
    switch (riskCategory) {
        case 'VeryLow':
            return 'Very Low';
        case 'Low':
            return 'Low';
        case 'Medium':
            return 'Medium';
        case 'High':
            return 'High';
        case 'VeryHigh':
            return 'Very High';
        default:
            return;
    }
};


export const getKeyAttributesValue = (label) => {
    switch (label) {
        case 'Rating':
            return 'rating';
        case 'Mode of Issue':
            return 'modeOfIssue';
        case 'Tax Saving':
            return 'taxSaving';
        case "Collateral Security":
            return 'collateralSecurity';
         case 'Seniority':
            return 'seniority';
        default:
            return ''
    }
}

export const getRiskRatingName = (opportunityRiskRatings) => {
    return opportunityRiskRatings.counterParty.name + " " + opportunityRiskRatings.rating
}