const buttonTypes = Object.freeze({
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    BORDERLESS: 'borderless',
    TEXT: 'text',
    DEFAULT: 'primary',
});

const buttonSizes = Object.freeze({
    EXTRA_SMALL: 'extraSmall',
    SMALL: 'small',
    REGULAR: 'regular',
    LARGE: 'large',
    DEFAULT: 'regular',
});

const badgeType = Object.freeze({
    WARNING: 'warning',
    SUCCESS: 'success',
    INFO: 'info',
    DANGER: 'danger',
    OUTLINE: 'outline',
    DEFAULT: 'success',
});

export { buttonTypes, buttonSizes, badgeType };
